// List of Salmonella genotypes
export const genotypes = [

  '0',
  '0.0.1',
  '0.0.2',
  '0.0.3',
  '0.1',
  '0.1.1',
  '0.1.2',
  '0.1.3',
  '1',
  '1.1',
  '1.1.1',
  '1.1.2',
  '1.1.3',
  '1.1.4',
  '1.2',
  '1.2.1',
  '2',
  '2.0.1',
  '2.0.2',
  '2.1',
  '2.1.1',
  '2.1.2',
  '2.1.3',
  '2.1.4',
  '2.1.5',
  '2.1.6',
  '2.1.7',
  '2.1.8',
  '2.1.9',
  '2.1.7.1',
  '2.1.7.2',
  '2.2',
  '2.2.1',
  '2.2.2',
  '2.2.3',
  '2.2.4',
  '2.3',
  '2.3.1',
  '2.3.2',
  '2.3.3',
  '2.3.4',
  '2.3.5',
  '2.4',
  '2.4.1',
  '2.5',
  '2.5.1',
  '2.5.2',
  '3',
  '3.0.1',
  '3.0.2',
  '3.1',
  '3.1.1',
  '3.1.2',
  '3.2',
  '3.2.1',
  '3.2.2',
  '3.3',
  '3.3.1',
  '3.3.2',
  '3.3.2.Bd1',
  '3.3.2.Bd2',
  '3.4',
  '3.5',
  '3.5.1',
  '3.5.2',
  '3.5.3',
  '3.5.4',
  '3.5.4.1',
  '3.5.4.2',
  '3.5.4.3',
  '4',
  '4.1',
  '4.1.1',
  '4.2',
  '4.2.1',
  '4.2.2',
  '4.2.3',
  '4.3.1',
  '4.3.1.1',
  '4.3.1.1.P1',
  '4.3.1.1.EA1',
  '4.3.1.2',
  '4.3.1.2.EA2',
  '4.3.1.2.EA3',
  '4.3.1.2.1',
  '4.3.1.2.1.1',
  '4.3.1.3',
  '4.3.1.3.Bdq'
].sort((a, b) => a.localeCompare(b));
