const countries = [
  'Zimbabwe', 'Zambia', 'Yemen', 'Vietnam', 'Venezuela', 'Vatican', 'Vanuatu', 'Uzbekistan', 'Uruguay', 'Micronesia',
  'Marshall Is.', 'N. Mariana Is.', 'U.S. Virgin Is.', 'Guam', 'American Samoa', 'Puerto Rico', 'United States of America',
  'S. Geo. and the Is.', 'Br. Indian Ocean Ter.', 'Saint Helena', 'Pitcairn Is.', 'Anguilla', 'Falkland Is.', 'Cayman Is.',
  'Bermuda', 'British Virgin Is.', 'Turks and Caicos Is.', 'Montserrat', 'Jersey', 'Guernsey', 'Isle of Man', 'United Kingdom',
  'United Arab Emirates', 'Ukraine', 'Uganda', 'Turkmenistan', 'Turkey', 'Tunisia', 'Trinidad and Tobago', 'Tonga', 'Togo',
  'Timor-Leste', 'Thailand', 'Tanzania', 'Tajikistan', 'Taiwan', 'Syria', 'Switzerland', 'Sweden', 'eSwatini', 'Suriname',
  'S. Sudan', 'Sudan', 'Sri Lanka', 'Spain', 'South Korea', 'South Africa', 'Somalia', 'Somaliland', 'Solomon Is.', 'Slovakia',
  'Slovenia', 'Singapore', 'Sierra Leone', 'Seychelles', 'Serbia', 'Senegal', 'Saudi Arabia', 'São Tomé and Principe', 'San Marino',
  'Samoa', 'St. Vin. and Gren.', 'Saint Lucia', 'St. Kitts and Nevis', 'Rwanda', 'Russia', 'Romania', 'Qatar', 'Portugal',
  'Poland', 'Philippines', 'Peru', 'Paraguay', 'Papua New Guinea', 'Panama', 'Palau', 'Pakistan', 'Oman', 'Norway', 'North Korea',
  'Nigeria', 'Niger', 'Nicaragua', 'New Zealand', 'Niue', 'Cook Is.', 'Netherlands', 'Aruba', 'Curaçao', 'Nepal', 
  'Nauru', 'Namibia', 'Mozambique', 'Morocco', 'W. Sahara', 'Montenegro', 'Mongolia', 'Moldova', 'Monaco', 'Mexico', 
  'Mauritius', 'Mauritania', 'Malta', 'Mali', 'Maldives', 'Malaysia', 'Malawi', 'Madagascar', 'North Macedonia', 'Luxembourg', 
  'Lithuania', 'Liechtenstein', 'Libya', 'Liberia', 'Lesotho', 'Lebanon', 'Latvia', 'Laos', 'Kyrgyzstan', 'Kuwait', 'Kosovo', 
  'Kiribati', 'Kenya', 'Kazakhstan', 'Jordan', 'Japan', 'Jamaica', 'Italy', 'Israel', 'Palestine', 'Ireland', 'Iraq', 'Iran', 
  'Indonesia', 'India', 'Iceland', 'Hungary', 'Honduras', 'Haiti', 'Guyana', 'Guinea-Bissau', 'Guinea', 'Guatemala', 'Grenada', 
  'Greece', 'Ghana', 'Germany', 'Georgia', 'Gambia', 'Gabon', 'French Guiana', 'France', 'St. Pierre and Miquelon', 
  'Wallis and Futuna Is.', 'St-Martin', 'St-Barthélemy', 'Fr. Polynesia', 'New Caledonia', 'Fr. S. Antarctic Lands', 'Åland', 
  'Finland', 'Fiji', 'Ethiopia', 'Estonia', 'Eritrea', 'Eq. Guinea', 'El Salvador', 'Egypt', 'Ecuador', 'Dominican Rep.', 
  'Dominica', 'Djibouti', 'Greenland', 'Faeroe Is.', 'Denmark', 'Czechia', 'N. Cyprus', 'Cyprus', 'Cuba', 'Croatia', 
  "Côte d'Ivoire", 'Costa Rica', 'Dem. Rep. Congo', 'Congo', 'Comoros', 'Colombia', 'China', 'Macao', 'Hong Kong', 'Chile',
  'Chad', 'Central African Rep.', 'Cabo Verde', 'Canada', 'Cameroon', 'Cambodia', 'Myanmar', 'Burundi', 'Burkina Faso', 
  'Bulgaria', 'Brunei', 'Brazil', 'Botswana', 'Bosnia and Herz.', 'Bolivia', 'Bhutan', 'Benin', 'Belize', 'Belgium', 'Belarus', 
  'Barbados', 'Bangladesh', 'Bahrain', 'Bahamas', 'Azerbaijan', 'Austria', 'Australia', 'Indian Ocean Ter.', 
  'Heard I. and McDonald Is.', 'Norfolk Island', 'Ashmore and Cartier Is.', 'Armenia', 'Argentina', 'Antigua and Barb.', 
  'Angola', 'Andorra', 'Algeria', 'Albania', 'Afghanistan', 'Siachen Glacier', 'Antarctica', 'Sint Maarten', 'Tuvalu'
].sort();
export default countries;